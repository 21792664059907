
































import { Component, Vue } from "vue-property-decorator";
import CardBox from "@/components/card-box/index.vue";
import { Form } from "element-ui";
import { uuid } from "@/tool";
import * as api from "@/api/scoreReport";
import { appTitle } from "@/utils/public-consts";

@Component({
  name: "ScoreProjectEditor",
  components: {
    CardBox,
  },
})
export default class extends Vue {
  private pageType: string = "";
  private ruleForm: api.ExamScoreProject = new api.ExamScoreProject();
  private rules = {};
  private title: string = "新增统分项目";
  private type(): string {
    if (this.pageType === "add") {
      this.title = "新增统分项目";
      this.$route.meta.title = "新增统分项目";
      document.title = `新增统分项目 | ${appTitle}`;
      return "立即创建";
    }
    if (this.pageType === "edit") {
      this.title = "编辑统分项目";
      this.$route.meta.title = "编辑统分项目";
      document.title = `编辑统分项目 | ${appTitle}`;
      return "保 存";
    }
    return "立即创建";
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }
  private loading: boolean = false;
  private async submitForm() {
    try {
      this.loading = true;
      const valid = await (this.$refs.ruleForm as Form).validate();
      if (valid) {
        await api.postExamProject(this.ruleForm);
        this.$message({
          message: this.pageType === "add" ? "添加成功" : "保存成功",
          type: "success",
        });
      }
    } finally {
      this.loading = false;
    }
  }

  private async __init() {
    try {
      this.loading = true;

      const examProjectId = this.$route.query.examProjectId;
      if (examProjectId) {
        api.getExamProject(examProjectId as string).then((res: any) => {
          this.ruleForm = res.data;
        });
      }
      this.pageType = this.$route.query.type as string;
    } finally {
      this.loading = false;
    }
  }
  mounted() {
    this.__init();
  }
}
